import type { editor } from 'monaco-editor';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'GitHub Dark',
  base: 'vs-dark',
  inherit: true,
  rules: [],
  colors: {
    focusBorder: '#1f6feb',
    foreground: '#e6edf3',
    descriptionForeground: '#7d8590',
    errorForeground: '#f85149',
    'textLink.foreground': '#2f81f7',
    'textLink.activeForeground': '#2f81f7',
    'textBlockQuote.background': '#010409',
    'textBlockQuote.border': '#30363d',
    'textCodeBlock.background': '#6e768166',
    'textPreformat.foreground': '#7d8590',
    'textSeparator.foreground': '#21262d',
    'icon.foreground': '#7d8590',
    'keybindingLabel.foreground': '#e6edf3',
    'button.background': '#238636',
    'button.foreground': '#ffffff',
    'button.hoverBackground': '#2ea043',
    'button.secondaryBackground': '#282e33',
    'button.secondaryForeground': '#c9d1d9',
    'button.secondaryHoverBackground': '#30363d',
    'checkbox.background': '#161b22',
    'checkbox.border': '#30363d',
    'dropdown.background': '#161b22',
    'dropdown.border': '#30363d',
    'dropdown.foreground': '#e6edf3',
    'dropdown.listBackground': '#161b22',
    'input.background': '#0d1117',
    'input.border': '#30363d',
    'input.foreground': '#e6edf3',
    'input.placeholderForeground': '#6e7681',
    'badge.foreground': '#ffffff',
    'badge.background': '#1f6feb',
    'progressBar.background': '#1f6feb',
    'titleBar.activeForeground': '#7d8590',
    'titleBar.activeBackground': '#0d1117',
    'titleBar.inactiveForeground': '#7d8590',
    'titleBar.inactiveBackground': '#010409',
    'titleBar.border': '#30363d',
    'activityBar.foreground': '#e6edf3',
    'activityBar.inactiveForeground': '#7d8590',
    'activityBar.background': '#0d1117',
    'activityBarBadge.foreground': '#ffffff',
    'activityBarBadge.background': '#1f6feb',
    'activityBar.activeBorder': '#f78166',
    'activityBar.border': '#30363d',
    'sideBar.foreground': '#e6edf3',
    'sideBar.background': '#010409',
    'sideBar.border': '#30363d',
    'sideBarTitle.foreground': '#e6edf3',
    'sideBarSectionHeader.foreground': '#e6edf3',
    'sideBarSectionHeader.background': '#010409',
    'sideBarSectionHeader.border': '#30363d',
    'list.hoverForeground': '#e6edf3',
    'list.inactiveSelectionForeground': '#e6edf3',
    'list.activeSelectionForeground': '#e6edf3',
    'list.hoverBackground': '#6e76811a',
    'list.inactiveSelectionBackground': '#6e768166',
    'list.activeSelectionBackground': '#6e768166',
    'list.focusForeground': '#e6edf3',
    'list.focusBackground': '#388bfd26',
    'list.inactiveFocusBackground': '#388bfd26',
    'list.highlightForeground': '#2f81f7',
    'tree.indentGuidesStroke': '#21262d',
    'notificationCenterHeader.foreground': '#7d8590',
    'notificationCenterHeader.background': '#161b22',
    'notifications.foreground': '#e6edf3',
    'notifications.background': '#161b22',
    'notifications.border': '#30363d',
    'notificationsErrorIcon.foreground': '#f85149',
    'notificationsWarningIcon.foreground': '#d29922',
    'notificationsInfoIcon.foreground': '#2f81f7',
    'pickerGroup.border': '#30363d',
    'pickerGroup.foreground': '#7d8590',
    'quickInput.background': '#161b22',
    'quickInput.foreground': '#e6edf3',
    'statusBar.foreground': '#7d8590',
    'statusBar.background': '#0d1117',
    'statusBar.border': '#30363d',
    'statusBar.focusBorder': '#1f6feb80',
    'statusBar.noFolderBackground': '#0d1117',
    'statusBar.debuggingForeground': '#ffffff',
    'statusBar.debuggingBackground': '#da3633',
    'statusBarItem.prominentBackground': '#6e768166',
    'statusBarItem.remoteForeground': '#e6edf3',
    'statusBarItem.remoteBackground': '#30363d',
    'statusBarItem.hoverBackground': '#e6edf314',
    'statusBarItem.activeBackground': '#e6edf31f',
    'statusBarItem.focusBorder': '#1f6feb',
    'editorGroupHeader.tabsBackground': '#010409',
    'editorGroupHeader.tabsBorder': '#30363d',
    'editorGroup.border': '#30363d',
    'tab.activeForeground': '#e6edf3',
    'tab.inactiveForeground': '#7d8590',
    'tab.inactiveBackground': '#010409',
    'tab.activeBackground': '#0d1117',
    'tab.hoverBackground': '#0d1117',
    'tab.unfocusedHoverBackground': '#6e76811a',
    'tab.border': '#30363d',
    'tab.unfocusedActiveBorderTop': '#30363d',
    'tab.activeBorder': '#0d1117',
    'tab.unfocusedActiveBorder': '#0d1117',
    'tab.activeBorderTop': '#f78166',
    'breadcrumb.foreground': '#7d8590',
    'breadcrumb.focusForeground': '#e6edf3',
    'breadcrumb.activeSelectionForeground': '#7d8590',
    'breadcrumbPicker.background': '#161b22',
    'editor.foreground': '#e6edf3',
    'editor.background': '#0d1117',
    'editorWidget.background': '#161b22',
    'editor.foldBackground': '#6e76811a',
    'editor.lineHighlightBackground': '#6e76811a',
    'editorLineNumber.foreground': '#6e7681',
    'editorLineNumber.activeForeground': '#e6edf3',
    'editorIndentGuide.background': '#e6edf31f',
    'editorIndentGuide.activeBackground': '#e6edf33d',
    'editorWhitespace.foreground': '#484f58',
    'editorCursor.foreground': '#2f81f7',
    'editor.findMatchBackground': '#9e6a03',
    'editor.findMatchHighlightBackground': '#f2cc6080',
    'editor.linkedEditingBackground': '#2f81f712',
    'editor.selectionHighlightBackground': '#3fb95040',
    'editor.wordHighlightBackground': '#6e768180',
    'editor.wordHighlightBorder': '#6e768199',
    'editor.wordHighlightStrongBackground': '#6e76814d',
    'editor.wordHighlightStrongBorder': '#6e768199',
    'editorBracketMatch.background': '#3fb95040',
    'editorBracketMatch.border': '#3fb95099',
    'editorInlayHint.background': '#8b949e33',
    'editorInlayHint.foreground': '#7d8590',
    'editorInlayHint.typeBackground': '#8b949e33',
    'editorInlayHint.typeForeground': '#7d8590',
    'editorInlayHint.paramBackground': '#8b949e33',
    'editorInlayHint.paramForeground': '#7d8590',
    'editorGutter.modifiedBackground': '#bb800966',
    'editorGutter.addedBackground': '#2ea04366',
    'editorGutter.deletedBackground': '#f8514966',
    'diffEditor.insertedLineBackground': '#23863626',
    'diffEditor.insertedTextBackground': '#3fb9504d',
    'diffEditor.removedLineBackground': '#da363326',
    'diffEditor.removedTextBackground': '#ff7b724d',
    'scrollbar.shadow': '#484f5833',
    'scrollbarSlider.background': '#8b949e33',
    'scrollbarSlider.hoverBackground': '#8b949e3d',
    'scrollbarSlider.activeBackground': '#8b949e47',
    'editorOverviewRuler.border': '#010409',
    'minimapSlider.background': '#8b949e33',
    'minimapSlider.hoverBackground': '#8b949e3d',
    'minimapSlider.activeBackground': '#8b949e47',
    'panel.background': '#010409',
    'panel.border': '#30363d',
    'panelTitle.activeBorder': '#f78166',
    'panelTitle.activeForeground': '#e6edf3',
    'panelTitle.inactiveForeground': '#7d8590',
    'panelInput.border': '#30363d',
    'debugIcon.breakpointForeground': '#f85149',
    'debugConsole.infoForeground': '#8b949e',
    'debugConsole.warningForeground': '#d29922',
    'debugConsole.errorForeground': '#ffa198',
    'debugConsole.sourceForeground': '#e3b341',
    'debugConsoleInputIcon.foreground': '#bc8cff',
    'debugTokenExpression.name': '#79c0ff',
    'debugTokenExpression.value': '#a5d6ff',
    'debugTokenExpression.string': '#a5d6ff',
    'debugTokenExpression.boolean': '#56d364',
    'debugTokenExpression.number': '#56d364',
    'debugTokenExpression.error': '#ffa198',
    'symbolIcon.arrayForeground': '#f0883e',
    'symbolIcon.booleanForeground': '#58a6ff',
    'symbolIcon.classForeground': '#f0883e',
    'symbolIcon.colorForeground': '#79c0ff',
    'symbolIcon.constructorForeground': '#d2a8ff',
    'symbolIcon.enumeratorForeground': '#f0883e',
    'symbolIcon.enumeratorMemberForeground': '#58a6ff',
    'symbolIcon.eventForeground': '#6e7681',
    'symbolIcon.fieldForeground': '#f0883e',
    'symbolIcon.fileForeground': '#d29922',
    'symbolIcon.folderForeground': '#d29922',
    'symbolIcon.functionForeground': '#bc8cff',
    'symbolIcon.interfaceForeground': '#f0883e',
    'symbolIcon.keyForeground': '#58a6ff',
    'symbolIcon.keywordForeground': '#ff7b72',
    'symbolIcon.methodForeground': '#bc8cff',
    'symbolIcon.moduleForeground': '#ff7b72',
    'symbolIcon.namespaceForeground': '#ff7b72',
    'symbolIcon.nullForeground': '#58a6ff',
    'symbolIcon.numberForeground': '#3fb950',
    'symbolIcon.objectForeground': '#f0883e',
    'symbolIcon.operatorForeground': '#79c0ff',
    'symbolIcon.packageForeground': '#f0883e',
    'symbolIcon.propertyForeground': '#f0883e',
    'symbolIcon.referenceForeground': '#58a6ff',
    'symbolIcon.snippetForeground': '#58a6ff',
    'symbolIcon.stringForeground': '#79c0ff',
    'symbolIcon.structForeground': '#f0883e',
    'symbolIcon.textForeground': '#79c0ff',
    'symbolIcon.typeParameterForeground': '#79c0ff',
    'symbolIcon.unitForeground': '#58a6ff',
    'symbolIcon.variableForeground': '#f0883e',
    'terminal.foreground': '#e6edf3',
    'terminal.ansiBlack': '#484f58',
    'terminal.ansiRed': '#ff7b72',
    'terminal.ansiGreen': '#3fb950',
    'terminal.ansiYellow': '#d29922',
    'terminal.ansiBlue': '#58a6ff',
    'terminal.ansiMagenta': '#bc8cff',
    'terminal.ansiCyan': '#39c5cf',
    'terminal.ansiWhite': '#b1bac4',
    'terminal.ansiBrightBlack': '#6e7681',
    'terminal.ansiBrightRed': '#ffa198',
    'terminal.ansiBrightGreen': '#56d364',
    'terminal.ansiBrightYellow': '#e3b341',
    'terminal.ansiBrightBlue': '#79c0ff',
    'terminal.ansiBrightMagenta': '#d2a8ff',
    'terminal.ansiBrightCyan': '#56d4dd',
    'terminal.ansiBrightWhite': '#ffffff',
    'editorBracketHighlight.foreground1': '#79c0ff',
    'editorBracketHighlight.foreground2': '#56d364',
    'editorBracketHighlight.foreground3': '#e3b341',
    'editorBracketHighlight.foreground4': '#ffa198',
    'editorBracketHighlight.foreground5': '#ff9bce',
    'editorBracketHighlight.foreground6': '#d2a8ff',
    'editorBracketHighlight.unexpectedBracket.foreground': '#7d8590',
    'gitDecoration.addedResourceForeground': '#3fb950',
    'gitDecoration.modifiedResourceForeground': '#d29922',
    'gitDecoration.deletedResourceForeground': '#f85149',
    'gitDecoration.untrackedResourceForeground': '#3fb950',
    'gitDecoration.ignoredResourceForeground': '#6e7681',
    'gitDecoration.conflictingResourceForeground': '#db6d28',
    'gitDecoration.submoduleResourceForeground': '#7d8590',
    'debugToolBar.background': '#161b22',
    'editor.stackFrameHighlightBackground': '#bb800966',
    'editor.focusedStackFrameHighlightBackground': '#2ea04366',
    'peekViewEditor.matchHighlightBackground': '#bb800966',
    'peekViewResult.matchHighlightBackground': '#bb800966',
    'peekViewEditor.background': '#6e76811a',
    'peekViewResult.background': '#0d1117',
    'settings.headerForeground': '#e6edf3',
    'settings.modifiedItemIndicator': '#bb800966',
    'welcomePage.buttonBackground': '#21262d',
    'welcomePage.buttonHoverBackground': '#30363d',
  },
} as editor.IStandaloneThemeData;
