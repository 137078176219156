import { compress, decompress } from 'lz-string';
import { useEffect } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import styles from './app.module.css';
import Alert from './components/alert';
import { Console } from './components/console';
import Editor from './components/editor';
import HelpModal from './components/help';
import OptionsModal from './components/options';
import { Runner } from './components/runner';
import Toolbar from './components/toolbar';
import { useTerminal } from './contexts/terminal';
import { useEvent } from './libs/events';

export default function App() {
  const [{ settings }, dispatch] = useTerminal();

  useEvent('ON_CODE_CHANGE', ({ code }) => {
    localStorage.setItem('code', compress(code || ''));
  });

  useEvent('SET_SETTINGS', ({ settings }) => {
    localStorage.setItem('settings', compress(JSON.stringify(settings)));
  });

  useEffect(() => {
    const code = localStorage.getItem('code');

    if (code) {
      dispatch({ type: 'SET_CODE', code: decompress(code) });
    }

    const settings = localStorage.getItem('settings');

    if (settings) {
      dispatch({
        type: 'SET_SETTINGS',
        settings: JSON.parse(decompress(settings)),
      });
    }
  }, [dispatch]);

  useEffect(() => {
    document.documentElement.className = settings.options.theme!;
  }, [settings.options.theme]);

  return (
    <>
      <div className={styles.container}>
        <PanelGroup direction={settings.layout} autoSaveId="panel_size">
          <Panel>
            <Editor />
          </Panel>
          <PanelResizeHandle
            className={`${styles.resize} ${styles[settings.layout]}`}
          />
          <Panel>
            <Console />
          </Panel>
        </PanelGroup>
      </div>
      <Alert />
      <Toolbar />
      <Runner />
      <OptionsModal />
      <HelpModal />
    </>
  );
}
