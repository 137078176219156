import React, { useState } from 'react';

import Icons from '../icons';
import styles from './base.module.css';
import StringLog from './string';
import { ValueTypes } from '../../libs/constants';
import { getType } from '../../libs/utils';
import ArrayLog from './array';
import ErrorLog from './error';
import FunctionLog from './function';
import HTMLLog from './html';
import MapLog from './map';
import ObjectLog from './object';
import PromiseLog from './promise';
import ProxyLog from './proxy';
import SetLog from './set';

type LogWrapperProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>;

export function LogWrapper({ children, className, ...props }: LogWrapperProps) {
  return (
    <div {...props} className={`${styles.log} ${className ?? ''}`}>
      {children}
    </div>
  );
}

type CollapsableProps = React.HTMLAttributes<HTMLDivElement> & {
  toggle?: boolean;
};

export function Collapsable({
  toggle = false,
  children,
  ...props
}: React.PropsWithChildren<CollapsableProps>) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const onToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  const components = React.Children.toArray(children);

  return (
    <LogWrapper
      {...props}
      onClick={onToggle}
      className={`${props.className} ${styles.collapse}`}
    >
      {isCollapsed ? (
        <Icons.Right className={styles.icon} width={14} height={14} />
      ) : (
        <Icons.Down className={styles.icon} width={14} height={14} />
      )}
      {toggle ? (
        isCollapsed ? (
          components[0]
        ) : (
          components[1]
        )
      ) : (
        <>
          {components[0]}
          {!isCollapsed && components[1]}
        </>
      )}
    </LogWrapper>
  );
}

type PropertyListProps = {
  scope: WeakMap<any, any>;
  list: [string | number, any][];
  size?: {
    key: string;
    value: number;
  };
};

export function PropertyList({ scope, list, size }: PropertyListProps) {
  return (
    <ul className={styles.properties}>
      {list.map(([key, value], index) => {
        return (
          <li key={`property_list_${key}_${index}`}>
            <strong className={styles.key}>{key}:</strong>{' '}
            <LogValue scope={scope} value={value} />
          </li>
        );
      })}

      {size && (
        <li className={styles.length}>
          <strong className={styles.key}>{size.key}:</strong>{' '}
          <StringLog value={size.value} />
        </li>
      )}

      {list.length === 0 && !size && (
        <li>
          <i className={styles.no_props}>No properties</i>
        </li>
      )}
    </ul>
  );
}

interface LogValueProps {
  value: any;
  scope: WeakMap<any, any>;
  isMinimized?: boolean;
};

export function LogValue({ value, scope, isMinimized = false }: LogValueProps) {
  const type = scope.get(value)?.isProxy ? ValueTypes.PROXY : getType(value);

  if (type === ValueTypes.PROXY) {
    return (
      <ProxyLog
        value={value}
        scope={scope}
        isMinimized={isMinimized}
      />
    );
  }

  if (type === ValueTypes.NULL) {
    return <StringLog value={null} />;
  }

  if (type === ValueTypes.UNDEFINED) {
    return <StringLog value={undefined} />;
  }

  if (type === ValueTypes.NUMBER) {
    return <StringLog value={value} />;
  }

  if (type === ValueTypes.STRING) {
    return <StringLog value={`"${value}"`} />;
  }

  if (type === ValueTypes.BOOLEAN) {
    return <StringLog value={value} />;
  }

  if (type === ValueTypes.DATE) {
    return <StringLog value={value} />;
  }

  if (type === ValueTypes.REGEXP) {
    return <StringLog value={value} />;
  }

  if (type === ValueTypes.SYMBOL) {
    return <StringLog value={value} />;
  }

  if (type === ValueTypes.PROMISE) {
    return (
      <PromiseLog
        value={value}
        scope={scope}
        isMinimized={isMinimized}
      />
    );
  }

  if (type === ValueTypes.FUNCTION) {
    return (
      <FunctionLog
        value={value}
        isMinimized={isMinimized}
       
      />
    );
  }

  if (type === ValueTypes.ERROR) {
    return (
      <ErrorLog
        value={value}
        isMinimized={isMinimized}
       
      />
    );
  }

  if (type === ValueTypes.ARRAY) {
    return (
      <ArrayLog
        value={value}
        scope={scope}
        isMinimized={isMinimized}    
      />
    );
  }

  if (type === ValueTypes.MAP) {
    return (
      <MapLog
        value={value}
        scope={scope}
        isMinimized={isMinimized}
      />
    );
  }

  if (type === ValueTypes.SET) {
    return (
      <SetLog
        value={value}
        scope={scope}
        isMinimized={isMinimized}
      />
    );
  }

  if (type === ValueTypes.HTML_ELEMENT) {
    return (
      <HTMLLog
        value={value}
        scope={scope}
        isMinimized={isMinimized}
       
      />
    );
  }

  if (type === ValueTypes.OBJECT) {
    return (
      <ObjectLog
        value={value}
        scope={scope}
        isMinimized={isMinimized}
      />
    );
  }

  return (
    <StringLog
      className=""
      value={value?.toString() ?? JSON.stringify(value)}
    />
  );
}