import { useEffect, useRef } from 'react';

import { useTerminal } from '../contexts/terminal';
import { useEvent } from '../libs/events';
import styles from './console.module.css';
import Icons from './icons';
import { LogValue } from './log-items/base';

export function Console() {
  const [{ logs }, dispatch] = useTerminal();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [containerRef, logs]);

  useEvent(
    'keydown',
    (_, event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: 'CLEAR_LOGS' });
      }
    },
    [dispatch],
  );

  return (
    <div ref={containerRef} className={styles.container}>
      {!logs.length && (
        <div className={styles.help}>
          <strong>Keyboard shortcuts</strong>
          <ul>
            <li>
              <kbd>Cmd</kbd> + <kbd>Enter</kbd> - Run code
            </li>
            <li>
              <kbd>Cmd</kbd> + <kbd>K</kbd> - Clear console
            </li>
            <li>
              <kbd>Cmd</kbd> + <kbd>/</kbd> - Show help
            </li>
            <li>
              <kbd>Cmd</kbd> + <kbd>,</kbd> - Show settings
            </li>
          </ul>

          <p>
            You can reach me on X (Twitter){' '}
            <a
              target="_blank"
              title="Twitter: @sonnyt"
              rel="noopener noreferrer"
              href="https://twitter.com/sonnyt"
            >
              here
            </a>
            . I'd love to hear your feedback or suggestions.
          </p>
        </div>
      )}
      <ul className={styles.logs}>
        {logs.map((log) => (
          <li key={`log_${log.id}`} className={styles[log.type]}>
            {log.type === 'error' && (
              <Icons.Error className={styles.icon} width={14} height={14} />
            )}

            {log.type === 'warn' && (
              <Icons.Warning className={styles.icon} width={14} height={14} />
            )}

            {log.content.map((value, index) => {
              return (
                <LogValue
                  value={value}
                  scope={log.scope}
                  key={`log_${log.id}_${index}`}
                />
              );
            })}
          </li>
        ))}
      </ul>
    </div>
  );
}
