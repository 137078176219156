import type { editor } from 'monaco-editor';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'Dracula',
  base: 'vs-dark',
  inherit: true,
  rules: [],
  colors: {
    'terminal.background': '#282A36',
    'terminal.foreground': '#F8F8F2',
    'terminal.ansiBrightBlack': '#6272A4',
    'terminal.ansiBrightRed': '#FF6E6E',
    'terminal.ansiBrightGreen': '#69FF94',
    'terminal.ansiBrightYellow': '#FFFFA5',
    'terminal.ansiBrightBlue': '#D6ACFF',
    'terminal.ansiBrightMagenta': '#FF92DF',
    'terminal.ansiBrightCyan': '#A4FFFF',
    'terminal.ansiBrightWhite': '#FFFFFF',
    'terminal.ansiBlack': '#21222C',
    'terminal.ansiRed': '#FF5555',
    'terminal.ansiGreen': '#50FA7B',
    'terminal.ansiYellow': '#F1FA8C',
    'terminal.ansiBlue': '#BD93F9',
    'terminal.ansiMagenta': '#FF79C6',
    'terminal.ansiCyan': '#8BE9FD',
    'terminal.ansiWhite': '#F8F8F2',
    focusBorder: '#6272A4',
    foreground: '#F8F8F2',
    'selection.background': '#BD93F9',
    errorForeground: '#FF5555',
    'button.background': '#44475A',
    'button.foreground': '#F8F8F2',
    'button.secondaryBackground': '#282A36',
    'button.secondaryForeground': '#F8F8F2',
    'button.secondaryHoverBackground': '#343746',
    'dropdown.background': '#343746',
    'dropdown.border': '#191A21',
    'dropdown.foreground': '#F8F8F2',
    'input.background': '#282A36',
    'input.foreground': '#F8F8F2',
    'input.border': '#191A21',
    'input.placeholderForeground': '#6272A4',
    'inputOption.activeBorder': '#BD93F9',
    'inputValidation.infoBorder': '#FF79C6',
    'inputValidation.warningBorder': '#FFB86C',
    'inputValidation.errorBorder': '#FF5555',
    'badge.foreground': '#F8F8F2',
    'badge.background': '#44475A',
    'progressBar.background': '#FF79C6',
    'list.activeSelectionBackground': '#44475A',
    'list.activeSelectionForeground': '#F8F8F2',
    'list.dropBackground': '#44475A',
    'list.focusBackground': '#44475A75',
    'list.highlightForeground': '#8BE9FD',
    'list.hoverBackground': '#44475A75',
    'list.inactiveSelectionBackground': '#44475A75',
    'list.warningForeground': '#FFB86C',
    'list.errorForeground': '#FF5555',
    'activityBar.background': '#343746',
    'activityBar.inactiveForeground': '#6272A4',
    'activityBar.foreground': '#F8F8F2',
    'activityBar.activeBorder': '#FF79C680',
    'activityBar.activeBackground': '#BD93F910',
    'activityBarBadge.background': '#FF79C6',
    'activityBarBadge.foreground': '#F8F8F2',
    'sideBar.background': '#21222C',
    'sideBarTitle.foreground': '#F8F8F2',
    'sideBarSectionHeader.background': '#282A36',
    'sideBarSectionHeader.border': '#191A21',
    'editorGroup.border': '#BD93F9',
    'editorGroup.dropBackground': '#44475A70',
    'editorGroupHeader.tabsBackground': '#191A21',
    'tab.activeBackground': '#282A36',
    'tab.activeForeground': '#F8F8F2',
    'tab.border': '#191A21',
    'tab.activeBorderTop': '#FF79C680',
    'tab.inactiveBackground': '#21222C',
    'tab.inactiveForeground': '#6272A4',
    'editor.foreground': '#F8F8F2',
    'editor.background': '#282A36',
    'editorLineNumber.foreground': '#6272A4',
    'editor.selectionBackground': '#44475A',
    'editor.selectionHighlightBackground': '#424450',
    'editor.foldBackground': '#21222C80',
    'editor.wordHighlightBackground': '#8BE9FD50',
    'editor.wordHighlightStrongBackground': '#50FA7B50',
    'editor.findMatchBackground': '#FFB86C80',
    'editor.findMatchHighlightBackground': '#FFFFFF40',
    'editor.findRangeHighlightBackground': '#44475A75',
    'editor.hoverHighlightBackground': '#8BE9FD50',
    'editor.lineHighlightBorder': '#44475A',
    'editorLink.activeForeground': '#8BE9FD',
    'editor.rangeHighlightBackground': '#BD93F915',
    'editor.snippetTabstopHighlightBackground': '#282A36',
    'editor.snippetTabstopHighlightBorder': '#6272A4',
    'editor.snippetFinalTabstopHighlightBackground': '#282A36',
    'editor.snippetFinalTabstopHighlightBorder': '#50FA7B',
    'editorWhitespace.foreground': '#FFFFFF1A',
    'editorIndentGuide.background': '#FFFFFF1A',
    'editorIndentGuide.activeBackground': '#FFFFFF45',
    'editorRuler.foreground': '#FFFFFF1A',
    'editorCodeLens.foreground': '#6272A4',
    'editorBracketHighlight.foreground1': '#F8F8F2',
    'editorBracketHighlight.foreground2': '#FF79C6',
    'editorBracketHighlight.foreground3': '#8BE9FD',
    'editorBracketHighlight.foreground4': '#50FA7B',
    'editorBracketHighlight.foreground5': '#BD93F9',
    'editorBracketHighlight.foreground6': '#FFB86C',
    'editorBracketHighlight.unexpectedBracket.foreground': '#FF5555',
    'editorOverviewRuler.border': '#191A21',
    'editorOverviewRuler.selectionHighlightForeground': '#FFB86C',
    'editorOverviewRuler.wordHighlightForeground': '#8BE9FD',
    'editorOverviewRuler.wordHighlightStrongForeground': '#50FA7B',
    'editorOverviewRuler.modifiedForeground': '#8BE9FD80',
    'editorOverviewRuler.addedForeground': '#50FA7B80',
    'editorOverviewRuler.deletedForeground': '#FF555580',
    'editorOverviewRuler.errorForeground': '#FF555580',
    'editorOverviewRuler.warningForeground': '#FFB86C80',
    'editorOverviewRuler.infoForeground': '#8BE9FD80',
    'editorError.foreground': '#FF5555',
    'editorWarning.foreground': '#8BE9FD',
    'editorGutter.modifiedBackground': '#8BE9FD80',
    'editorGutter.addedBackground': '#50FA7B80',
    'editorGutter.deletedBackground': '#FF555580',
    'gitDecoration.modifiedResourceForeground': '#8BE9FD',
    'gitDecoration.deletedResourceForeground': '#FF5555',
    'gitDecoration.untrackedResourceForeground': '#50FA7B',
    'gitDecoration.ignoredResourceForeground': '#6272A4',
    'gitDecoration.conflictingResourceForeground': '#FFB86C',
    'diffEditor.insertedTextBackground': '#50FA7B20',
    'diffEditor.removedTextBackground': '#FF555550',
    'inlineChat.regionHighlight': '#343746',
    'editorWidget.background': '#21222C',
    'editorSuggestWidget.background': '#21222C',
    'editorSuggestWidget.foreground': '#F8F8F2',
    'editorSuggestWidget.selectedBackground': '#44475A',
    'editorHoverWidget.background': '#282A36',
    'editorHoverWidget.border': '#6272A4',
    'editorMarkerNavigation.background': '#21222C',
    'peekView.border': '#44475A',
    'peekViewEditor.background': '#282A36',
    'peekViewEditor.matchHighlightBackground': '#F1FA8C80',
    'peekViewResult.background': '#21222C',
    'peekViewResult.fileForeground': '#F8F8F2',
    'peekViewResult.lineForeground': '#F8F8F2',
    'peekViewResult.matchHighlightBackground': '#F1FA8C80',
    'peekViewResult.selectionBackground': '#44475A',
    'peekViewResult.selectionForeground': '#F8F8F2',
    'peekViewTitle.background': '#191A21',
    'peekViewTitleDescription.foreground': '#6272A4',
    'peekViewTitleLabel.foreground': '#F8F8F2',
    'merge.currentHeaderBackground': '#50FA7B90',
    'merge.incomingHeaderBackground': '#BD93F990',
    'editorOverviewRuler.currentContentForeground': '#50FA7B',
    'editorOverviewRuler.incomingContentForeground': '#BD93F9',
    'panel.background': '#282A36',
    'panel.border': '#BD93F9',
    'panelTitle.activeBorder': '#FF79C6',
    'panelTitle.activeForeground': '#F8F8F2',
    'panelTitle.inactiveForeground': '#6272A4',
    'statusBar.background': '#191A21',
    'statusBar.foreground': '#F8F8F2',
    'statusBar.debuggingBackground': '#FF5555',
    'statusBar.debuggingForeground': '#191A21',
    'statusBar.noFolderBackground': '#191A21',
    'statusBar.noFolderForeground': '#F8F8F2',
    'statusBarItem.prominentBackground': '#FF5555',
    'statusBarItem.prominentHoverBackground': '#FFB86C',
    'statusBarItem.remoteForeground': '#282A36',
    'statusBarItem.remoteBackground': '#BD93F9',
    'titleBar.activeBackground': '#21222C',
    'titleBar.activeForeground': '#F8F8F2',
    'titleBar.inactiveBackground': '#191A21',
    'titleBar.inactiveForeground': '#6272A4',
    'extensionButton.prominentForeground': '#F8F8F2',
    'extensionButton.prominentBackground': '#50FA7B90',
    'extensionButton.prominentHoverBackground': '#50FA7B60',
    'pickerGroup.border': '#BD93F9',
    'pickerGroup.foreground': '#8BE9FD',
    'debugToolBar.background': '#21222C',
    'walkThrough.embeddedEditorBackground': '#21222C',
    'settings.headerForeground': '#F8F8F2',
    'settings.modifiedItemIndicator': '#FFB86C',
    'settings.dropdownBackground': '#21222C',
    'settings.dropdownForeground': '#F8F8F2',
    'settings.dropdownBorder': '#191A21',
    'settings.checkboxBackground': '#21222C',
    'settings.checkboxForeground': '#F8F8F2',
    'settings.checkboxBorder': '#191A21',
    'settings.textInputBackground': '#21222C',
    'settings.textInputForeground': '#F8F8F2',
    'settings.textInputBorder': '#191A21',
    'settings.numberInputBackground': '#21222C',
    'settings.numberInputForeground': '#F8F8F2',
    'settings.numberInputBorder': '#191A21',
    'breadcrumb.foreground': '#6272A4',
    'breadcrumb.background': '#282A36',
    'breadcrumb.focusForeground': '#F8F8F2',
    'breadcrumb.activeSelectionForeground': '#F8F8F2',
    'breadcrumbPicker.background': '#191A21',
    'listFilterWidget.background': '#343746',
    'listFilterWidget.outline': '#424450',
    'listFilterWidget.noMatchesOutline': '#FF5555',
  },
} as editor.IStandaloneThemeData;
