import type { editor } from 'monaco-editor';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'GitHub Light',
  base: 'vs',
  inherit: true,
  rules: [],
  colors: {
    focusBorder: '#0969da',
    foreground: '#1f2328',
    descriptionForeground: '#656d76',
    errorForeground: '#cf222e',
    'textLink.foreground': '#0969da',
    'textLink.activeForeground': '#0969da',
    'textBlockQuote.background': '#f6f8fa',
    'textBlockQuote.border': '#d0d7de',
    'textCodeBlock.background': '#afb8c133',
    'textPreformat.foreground': '#656d76',
    'textSeparator.foreground': '#d8dee4',
    'icon.foreground': '#656d76',
    'keybindingLabel.foreground': '#1f2328',
    'button.background': '#1f883d',
    'button.foreground': '#ffffff',
    'button.hoverBackground': '#1a7f37',
    'button.secondaryBackground': '#ebecf0',
    'button.secondaryForeground': '#24292f',
    'button.secondaryHoverBackground': '#f3f4f6',
    'checkbox.background': '#f6f8fa',
    'checkbox.border': '#d0d7de',
    'dropdown.background': '#ffffff',
    'dropdown.border': '#d0d7de',
    'dropdown.foreground': '#1f2328',
    'dropdown.listBackground': '#ffffff',
    'input.background': '#ffffff',
    'input.border': '#d0d7de',
    'input.foreground': '#1f2328',
    'input.placeholderForeground': '#6e7781',
    'badge.foreground': '#ffffff',
    'badge.background': '#0969da',
    'progressBar.background': '#0969da',
    'titleBar.activeForeground': '#656d76',
    'titleBar.activeBackground': '#ffffff',
    'titleBar.inactiveForeground': '#656d76',
    'titleBar.inactiveBackground': '#f6f8fa',
    'titleBar.border': '#d0d7de',
    'activityBar.foreground': '#1f2328',
    'activityBar.inactiveForeground': '#656d76',
    'activityBar.background': '#ffffff',
    'activityBarBadge.foreground': '#ffffff',
    'activityBarBadge.background': '#0969da',
    'activityBar.activeBorder': '#fd8c73',
    'activityBar.border': '#d0d7de',
    'sideBar.foreground': '#1f2328',
    'sideBar.background': '#f6f8fa',
    'sideBar.border': '#d0d7de',
    'sideBarTitle.foreground': '#1f2328',
    'sideBarSectionHeader.foreground': '#1f2328',
    'sideBarSectionHeader.background': '#f6f8fa',
    'sideBarSectionHeader.border': '#d0d7de',
    'list.hoverForeground': '#1f2328',
    'list.inactiveSelectionForeground': '#1f2328',
    'list.activeSelectionForeground': '#1f2328',
    'list.hoverBackground': '#eaeef280',
    'list.inactiveSelectionBackground': '#afb8c133',
    'list.activeSelectionBackground': '#afb8c133',
    'list.focusForeground': '#1f2328',
    'list.focusBackground': '#ddf4ff',
    'list.inactiveFocusBackground': '#ddf4ff',
    'list.highlightForeground': '#0969da',
    'tree.indentGuidesStroke': '#d8dee4',
    'notificationCenterHeader.foreground': '#656d76',
    'notificationCenterHeader.background': '#f6f8fa',
    'notifications.foreground': '#1f2328',
    'notifications.background': '#ffffff',
    'notifications.border': '#d0d7de',
    'notificationsErrorIcon.foreground': '#cf222e',
    'notificationsWarningIcon.foreground': '#9a6700',
    'notificationsInfoIcon.foreground': '#0969da',
    'pickerGroup.border': '#d0d7de',
    'pickerGroup.foreground': '#656d76',
    'quickInput.background': '#ffffff',
    'quickInput.foreground': '#1f2328',
    'statusBar.foreground': '#656d76',
    'statusBar.background': '#ffffff',
    'statusBar.border': '#d0d7de',
    'statusBar.focusBorder': '#0969da80',
    'statusBar.noFolderBackground': '#ffffff',
    'statusBar.debuggingForeground': '#ffffff',
    'statusBar.debuggingBackground': '#cf222e',
    'statusBarItem.prominentBackground': '#afb8c133',
    'statusBarItem.remoteForeground': '#1f2328',
    'statusBarItem.remoteBackground': '#eaeef2',
    'statusBarItem.hoverBackground': '#1f232814',
    'statusBarItem.activeBackground': '#1f23281f',
    'statusBarItem.focusBorder': '#0969da',
    'editorGroupHeader.tabsBackground': '#f6f8fa',
    'editorGroupHeader.tabsBorder': '#d0d7de',
    'editorGroup.border': '#d0d7de',
    'tab.activeForeground': '#1f2328',
    'tab.inactiveForeground': '#656d76',
    'tab.inactiveBackground': '#f6f8fa',
    'tab.activeBackground': '#ffffff',
    'tab.hoverBackground': '#ffffff',
    'tab.unfocusedHoverBackground': '#eaeef280',
    'tab.border': '#d0d7de',
    'tab.unfocusedActiveBorderTop': '#d0d7de',
    'tab.activeBorder': '#ffffff',
    'tab.unfocusedActiveBorder': '#ffffff',
    'tab.activeBorderTop': '#fd8c73',
    'breadcrumb.foreground': '#656d76',
    'breadcrumb.focusForeground': '#1f2328',
    'breadcrumb.activeSelectionForeground': '#656d76',
    'breadcrumbPicker.background': '#ffffff',
    'editor.foreground': '#1f2328',
    'editor.background': '#ffffff',
    'editorWidget.background': '#ffffff',
    'editor.foldBackground': '#6e77811a',
    'editor.lineHighlightBackground': '#eaeef280',
    'editorLineNumber.foreground': '#8c959f',
    'editorLineNumber.activeForeground': '#1f2328',
    'editorIndentGuide.background': '#1f23281f',
    'editorIndentGuide.activeBackground': '#1f23283d',
    'editorWhitespace.foreground': '#afb8c1',
    'editorCursor.foreground': '#0969da',
    'editor.findMatchBackground': '#bf8700',
    'editor.findMatchHighlightBackground': '#fae17d80',
    'editor.linkedEditingBackground': '#0969da12',
    'editor.selectionHighlightBackground': '#4ac26b40',
    'editor.wordHighlightBackground': '#eaeef280',
    'editor.wordHighlightBorder': '#afb8c199',
    'editor.wordHighlightStrongBackground': '#afb8c14d',
    'editor.wordHighlightStrongBorder': '#afb8c199',
    'editorBracketMatch.background': '#4ac26b40',
    'editorBracketMatch.border': '#4ac26b99',
    'editorInlayHint.background': '#afb8c133',
    'editorInlayHint.foreground': '#656d76',
    'editorInlayHint.typeBackground': '#afb8c133',
    'editorInlayHint.typeForeground': '#656d76',
    'editorInlayHint.paramBackground': '#afb8c133',
    'editorInlayHint.paramForeground': '#656d76',
    'editorGutter.modifiedBackground': '#d4a72c66',
    'editorGutter.addedBackground': '#4ac26b66',
    'editorGutter.deletedBackground': '#ff818266',
    'diffEditor.insertedLineBackground': '#aceebb4d',
    'diffEditor.insertedTextBackground': '#6fdd8b80',
    'diffEditor.removedLineBackground': '#ffcecb4d',
    'diffEditor.removedTextBackground': '#ff818266',
    'scrollbar.shadow': '#6e778133',
    'scrollbarSlider.background': '#8c959f33',
    'scrollbarSlider.hoverBackground': '#8c959f3d',
    'scrollbarSlider.activeBackground': '#8c959f47',
    'editorOverviewRuler.border': '#ffffff',
    'minimapSlider.background': '#8c959f33',
    'minimapSlider.hoverBackground': '#8c959f3d',
    'minimapSlider.activeBackground': '#8c959f47',
    'panel.background': '#f6f8fa',
    'panel.border': '#d0d7de',
    'panelTitle.activeBorder': '#fd8c73',
    'panelTitle.activeForeground': '#1f2328',
    'panelTitle.inactiveForeground': '#656d76',
    'panelInput.border': '#d0d7de',
    'debugIcon.breakpointForeground': '#cf222e',
    'debugConsole.infoForeground': '#57606a',
    'debugConsole.warningForeground': '#7d4e00',
    'debugConsole.errorForeground': '#cf222e',
    'debugConsole.sourceForeground': '#9a6700',
    'debugConsoleInputIcon.foreground': '#6639ba',
    'debugTokenExpression.name': '#0550ae',
    'debugTokenExpression.value': '#0a3069',
    'debugTokenExpression.string': '#0a3069',
    'debugTokenExpression.boolean': '#116329',
    'debugTokenExpression.number': '#116329',
    'debugTokenExpression.error': '#a40e26',
    'symbolIcon.arrayForeground': '#953800',
    'symbolIcon.booleanForeground': '#0550ae',
    'symbolIcon.classForeground': '#953800',
    'symbolIcon.colorForeground': '#0a3069',
    'symbolIcon.constructorForeground': '#3e1f79',
    'symbolIcon.enumeratorForeground': '#953800',
    'symbolIcon.enumeratorMemberForeground': '#0550ae',
    'symbolIcon.eventForeground': '#57606a',
    'symbolIcon.fieldForeground': '#953800',
    'symbolIcon.fileForeground': '#7d4e00',
    'symbolIcon.folderForeground': '#7d4e00',
    'symbolIcon.functionForeground': '#6639ba',
    'symbolIcon.interfaceForeground': '#953800',
    'symbolIcon.keyForeground': '#0550ae',
    'symbolIcon.keywordForeground': '#a40e26',
    'symbolIcon.methodForeground': '#6639ba',
    'symbolIcon.moduleForeground': '#a40e26',
    'symbolIcon.namespaceForeground': '#a40e26',
    'symbolIcon.nullForeground': '#0550ae',
    'symbolIcon.numberForeground': '#116329',
    'symbolIcon.objectForeground': '#953800',
    'symbolIcon.operatorForeground': '#0a3069',
    'symbolIcon.packageForeground': '#953800',
    'symbolIcon.propertyForeground': '#953800',
    'symbolIcon.referenceForeground': '#0550ae',
    'symbolIcon.snippetForeground': '#0550ae',
    'symbolIcon.stringForeground': '#0a3069',
    'symbolIcon.structForeground': '#953800',
    'symbolIcon.textForeground': '#0a3069',
    'symbolIcon.typeParameterForeground': '#0a3069',
    'symbolIcon.unitForeground': '#0550ae',
    'symbolIcon.variableForeground': '#953800',
    'symbolIcon.constantForeground': '#116329',
    'terminal.foreground': '#1f2328',
    'terminal.ansiBlack': '#24292f',
    'terminal.ansiRed': '#cf222e',
    'terminal.ansiGreen': '#116329',
    'terminal.ansiYellow': '#4d2d00',
    'terminal.ansiBlue': '#0969da',
    'terminal.ansiMagenta': '#8250df',
    'terminal.ansiCyan': '#1b7c83',
    'terminal.ansiWhite': '#6e7781',
    'terminal.ansiBrightBlack': '#57606a',
    'terminal.ansiBrightRed': '#a40e26',
    'terminal.ansiBrightGreen': '#1a7f37',
    'terminal.ansiBrightYellow': '#633c01',
    'terminal.ansiBrightBlue': '#218bff',
    'terminal.ansiBrightMagenta': '#a475f9',
    'terminal.ansiBrightCyan': '#3192aa',
    'terminal.ansiBrightWhite': '#8c959f',
    'editorBracketHighlight.foreground1': '#0969da',
    'editorBracketHighlight.foreground2': '#1a7f37',
    'editorBracketHighlight.foreground3': '#9a6700',
    'editorBracketHighlight.foreground4': '#cf222e',
    'editorBracketHighlight.foreground5': '#bf3989',
    'editorBracketHighlight.foreground6': '#8250df',
    'editorBracketHighlight.unexpectedBracket.foreground': '#656d76',
    'gitDecoration.addedResourceForeground': '#1a7f37',
    'gitDecoration.modifiedResourceForeground': '#9a6700',
    'gitDecoration.deletedResourceForeground': '#cf222e',
    'gitDecoration.untrackedResourceForeground': '#1a7f37',
    'gitDecoration.ignoredResourceForeground': '#6e7781',
    'gitDecoration.conflictingResourceForeground': '#bc4c00',
    'gitDecoration.submoduleResourceForeground': '#656d76',
    'debugToolBar.background': '#ffffff',
    'editor.stackFrameHighlightBackground': '#d4a72c66',
    'editor.focusedStackFrameHighlightBackground': '#4ac26b66',
    'settings.headerForeground': '#1f2328',
    'settings.modifiedItemIndicator': '#d4a72c66',
    'welcomePage.buttonBackground': '#f6f8fa',
    'welcomePage.buttonHoverBackground': '#f3f4f6',
  },
} as editor.IStandaloneThemeData;
