import { getObjectName } from '../../libs/utils';
import { Collapsable, PropertyList, LogValue } from './base';
import styles from './object.module.css';
import StringLog from './string';

interface Props {
  value: Record<string, any>;
  scope: WeakMap<any, any>;
  isMinimized: boolean;
}

export default function ObjectLog({ value: obj, scope, isMinimized }: Props) {
  let prefix: string | null = getObjectName(obj);
  prefix = prefix === 'Object' ? null : prefix;

  const keys = Object.getOwnPropertyNames(obj);

  if (isMinimized) {
    return <StringLog value={prefix ?? `{…}`} className={styles.is_object} />;
  }

  return (
    <Collapsable className={styles.is_object}>
      <>
        {prefix ? `${prefix} {` : '{'}
        {keys.slice(0, 5).map((key, index) => {
          return (
            <>
              <span className={styles.key}>{key}</span>:{' '}
              <LogValue scope={scope} value={obj[key]} isMinimized />
              {index < keys.length - 1 && ', '}
            </>
          );
        })}
        {keys.length > 5 && '…'}
        {'}'}
      </>
      <>
        <PropertyList
          scope={scope}
          list={keys.map((key) => [key, obj[key]])}
        />
      </>
    </Collapsable>
  );
}
