import {
  loader,
  type BeforeMount,
  Editor as CodeEditor,
  type OnChange,
  type OnMount,
} from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { useCallback } from 'react';

import { useTerminal } from '../contexts/terminal';
import themes from '../themes';

export type EditorOptions = monaco.editor.IStandaloneEditorConstructionOptions;

loader.config({ monaco });

export default function Editor() {
  const [{ code, settings }, dispatch] = useTerminal();

  const beforeMount: BeforeMount = useCallback(
    (monaco) => {
      const compilerOptions =
        monaco.languages.typescript.typescriptDefaults.getCompilerOptions();

      monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        moduleResolution:
          monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        ...compilerOptions,
      });

      monaco.languages.typescript.typescriptDefaults.addExtraLib('declare module "https://*"');

      // Add themes
      for (const [name, theme] of Object.entries(themes)) {
        monaco.editor.defineTheme(name, theme);
      }

      // Add editor actions
      monaco.editor.addEditorAction({
        id: 'execute_code',
        label: 'Run Code',
        run: () => dispatch({ type: 'RUN_CODE' }),
        contextMenuOrder: 0,
        contextMenuGroupId: 'navigation',
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
      });

      monaco.editor.addEditorAction({
        id: 'clear_output',
        label: 'Clear Console',
        run: () => dispatch({ type: 'CLEAR_LOGS' }),
        contextMenuOrder: 1,
        contextMenuGroupId: 'navigation',
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK],
      });

      monaco.editor.addEditorAction({
        id: 'show_options',
        label: 'Show Settings',
        run: () => dispatch({ type: 'SHOW_OPTIONS' }),
        contextMenuOrder: 2,
        contextMenuGroupId: 'navigation',
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Comma],
      });
    },
    [dispatch],
  );

  const onMount: OnMount = useCallback((editor, monaco) => {
      const uri = editor.getModel()?.uri.toString()!;
      dispatch({ type: 'SET_EDITOR_URI', uri });

      monaco.editor.setTheme(settings.options.theme!);
      editor.focus();
    },
    [dispatch, settings.options.theme],
  );

  const onChange: OnChange = useCallback(
    (value) => {
      dispatch({ type: 'ON_CODE_CHANGE', code: value });
      dispatch({ type: 'SET_ALERT' });
    },
    [dispatch],
  );

  return (
    <CodeEditor
      value={code}
      theme={settings.options.theme}
      language={settings.options.language}
      onChange={onChange}
      beforeMount={beforeMount}
      onMount={onMount}
      options={{
        ...settings.options,
        insertSpaces: true,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        minimap: {
          enabled: false,
        },
        scrollbar: {
          vertical: 'auto',
        },
      }}
    />
  );
}
