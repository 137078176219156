import { useTerminal } from '../contexts/terminal';
import { useEvent } from '../libs/events';
import Dialog from './dialog';
import styles from './help.module.css';

export default function HelpModal() {
  const [{ showHelp }, dispatch] = useTerminal();

  useEvent(
    'keydown',
    (_, event: KeyboardEvent) => {
      if (event.metaKey && event.key === '/') {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: 'SHOW_HELP' });
      }
    },
    [dispatch],
  );

  const handleOnClose = () => {
    dispatch({ type: 'HIDE_HELP' });
  };

  return (
    <Dialog title="Help & FAQ" open={showHelp} onClose={handleOnClose}>
      <div className={styles.container}>
        <strong>Keyboard shortcuts</strong>
        <ul>
          <li>
            <kbd>Cmd</kbd> + <kbd>Enter</kbd> - Run code
          </li>
          <li>
            <kbd>Cmd</kbd> + <kbd>K</kbd> - Clear console
          </li>
          <li>
            <kbd>Cmd</kbd> + <kbd>/</kbd> - Show help
          </li>
          <li>
            <kbd>Cmd</kbd> + <kbd>,</kbd> - Show settings
          </li>
        </ul>
        <p>
          <strong>Can I use NPM packages?</strong> <br />
          Yes! JSLog allows you to import ES6 modules from a URL. Check out{' '}
          <a
            title="ESM>CDN"
            target="_blank"
            href="https://esm.sh"
            rel="noopener noreferrer"
          >
            esm.sh
          </a>{' '}
          for more info.
        </p>

        <p>
          <strong>Can I use React, Vue, Angular, etc?</strong> <br />
          No! JSLog is a simple tool for running JavaScript code. You can use it
          to test out code snippets, debug, or learn JavaScript. It doesn't have
          a DOM or support for frameworks.
        </p>

        <p>
          <strong>Where is my code saved?</strong> <br />
          Your code is saved in your browser's local storage. It will be there
          even if you close the tab or refresh the page.
        </p>

        <p>
          <strong>Can I contact you?</strong> <br />
          Yes! You can reach me on X (Twitter){' '}
          <a
            target="_blank"
            title="Twitter: @sonnyt"
            rel="noopener noreferrer"
            href="https://twitter.com/sonnyt"
          >
            here
          </a>
          . I'd love to hear your feedback or suggestions.
        </p>
      </div>
    </Dialog>
  );
}
