import styles from './array.module.css';
import { Collapsable, PropertyList, LogValue } from './base';
import StringLog from './string';

interface Props {
  value: unknown[];
  scope: WeakMap<any, any>;
  isMinimized: boolean;
}

export default function ArrayLog({ value: arr, scope, isMinimized }: Props) {
  if (isMinimized) {
    return <StringLog value={`Array(${arr.length})`} />;
  }

  return (
    <Collapsable className={styles.is_array}>
      <>
        <i>({arr.length}) </i>[
        {arr.slice(0, 5).map((value, index) => {
          return (
            <>
              <LogValue scope={scope} value={value} isMinimized />
              {index < arr.length - 1 && ','}
            </>
          );
        })}
        {arr.length > 5 && ' …'}]
      </>
      <>
        <PropertyList
          scope={scope}
          list={arr.map((value, index) => [index, value])}
          size={{ key: 'length', value: arr.length }}
        />
      </>
    </Collapsable>
  );
}
