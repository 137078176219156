import { Collapsable, PropertyList, LogValue } from './base';
import styles from './promise.module.css';
import StringLog from './string';

interface Props {
  value: Promise<any>;
  scope: WeakMap<any, any>;
  isMinimized: boolean;
}

export default function PromiseLog({ value: promise, scope, isMinimized }: Props) {
  if (isMinimized) {
    return <StringLog value="Promise" className={styles.is_promise} />;
  }

  const meta = scope.get(promise);

  return (
    <Collapsable className={styles.is_promise}>
      <>
        Promise {'{'}
        <i className={styles.state}>
          {'<'}
          {meta.state}
          {'>'}
        </i>
        {meta.value && (
          <>
            : <LogValue scope={scope} value={meta.value} isMinimized />
          </>
        )}
        {'}'}
      </>
      <>
        <PropertyList
          scope={scope}
          list={[
            ['[[PromiseState]]', meta.state],
            ['[[PromiseResult]]', meta.value],
          ]}
        />
      </>
    </Collapsable>
  );
}
