import { useTerminal } from '../contexts/terminal';
import { useEvent } from '../libs/events';
import styles from './alert.module.css';
import Icons from './icons';

export default function Alert() {
  const [{ alertText }, dispatch] = useTerminal();

  useEvent(
    'storage',
    () => {
      dispatch({
        type: 'SET_ALERT',
        text: 'Code is updated in another window. Please reload the page to view the latest changes.',
      });
    },
    [dispatch],
  );

  if (!alertText) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>{alertText}</div>
      <button
        type="button"
        title="Close Alert"
        onClick={() => dispatch({ type: 'SET_ALERT' })}
      >
        <Icons.Close width={14} height={14} className={styles.icon} />
      </button>
    </div>
  );
}
