import { Collapsable, PropertyList, LogValue } from './base';
import styles from './map.module.css';
import StringLog from './string';

interface Props {
  value: Map<any, any>;
  scope: WeakMap<any, any>;
  isMinimized: boolean;
}

export default function MapLog({ value: map, scope, isMinimized }: Props) {
  const mapArray = Array.from(map);

  if (isMinimized) {
    return <StringLog value={`Map(${map.size})`} className={styles.is_map} />;
  }

  return (
    <Collapsable className={styles.is_map}>
      <>
        Map({`${map.size}`}) {'{'}
        {mapArray.slice(0, 5).map(([key, value], index) => {
          return (
            <>
              <span className={styles.key}>{key}</span>
              {' =>'} <LogValue scope={scope} value={value} isMinimized />
              {index < mapArray.length - 1 && ', '}
            </>
          );
        })}
        {mapArray.length > 5 && '…'}
        {'}'}
      </>
      <>
        <PropertyList
          scope={scope}
          list={mapArray}
          size={{ key: 'size', value: map.size }}
        />
      </>
    </Collapsable>
  );
}
