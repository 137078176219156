import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app';
import { TerminalProvider } from './contexts/terminal';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <TerminalProvider>
      <App />
    </TerminalProvider>
  </React.StrictMode>,
);
