import { useTerminal } from '../contexts/terminal';
import Icons from './icons';
import styles from './toolbar.module.css';

export default function Toolbar() {
  const [state, dispatch] = useTerminal();

  if (state.alertText) {
    return null;
  }

  const errors = state.logs.filter(({ type }) => type === 'error');
  const warnings = state.logs.filter(({ type }) => type === 'warn');

  return (
    <div className={styles.container}>
      <div>
        {state.running && (
          <Icons.Loading
            width={14}
            height={14}
            className={`${styles.icon} ${styles.loading}`}
          />
        )}
        {!state.running && (
          <button
            type="button"
            title="Run Code (⌘Enter)"
            onClick={() => dispatch({ type: 'RUN_CODE' })}
          >
            <Icons.Run
              width={14}
              height={14}
              className={`${styles.icon} ${styles.run}`}
            />
          </button>
        )}
      </div>
      <div className={styles.center}></div>
      <div className={styles.tools}>
        <button
          type="button"
          onClick={() => dispatch({ type: 'SHOW_OPTIONS' })}
        >
          Spaces: {state.settings.options.tabSize}
        </button>
        <button
          type="button"
          onClick={() => dispatch({ type: 'SHOW_OPTIONS' })}
        >
          {state.settings.options.language === 'javascript'
            ? 'JavaScript'
            : 'TypeScript'}
        </button>
        <button
          type="button"
          title="FAQ & Help (⌘/)"
          onClick={() => dispatch({ type: 'SHOW_HELP' })}
        >
          <Icons.Question
            width={14}
            height={14}
            className={`${styles.icon} ${styles.settings}`}
          />
        </button>
        <button
          type="button"
          title="Show Settings (⌘,)"
          onClick={() => dispatch({ type: 'SHOW_OPTIONS' })}
        >
          <Icons.Settings
            width={14}
            height={14}
            className={`${styles.icon} ${styles.settings}`}
          />
        </button>

        <span>
          <Icons.Error
            width={14}
            height={14}
            className={`${styles.icon} ${styles.error}`}
          />
          {errors.length}
        </span>

        <span>
          <Icons.Warning
            width={14}
            height={14}
            className={`${styles.icon} ${styles.warning}`}
          />
          {warnings.length}
        </span>

        <button
          type="button"
          title="Clear Console (⌘K)"
          onClick={() => dispatch({ type: 'CLEAR_LOGS' })}
        >
          <Icons.Clear
            width={14}
            height={14}
            className={`${styles.icon} ${styles.clear}`}
          />
        </button>
      </div>
    </div>
  );
}
