import { useMonaco } from '@monaco-editor/react';
import { useCallback, useRef } from 'react';

import { useTerminal } from '../contexts/terminal';
import { useEvent } from '../libs/events';
import { consoleStub, promiseStub, proxyStub } from '../libs/stubs';
import { generateId } from '../libs/utils';

export function Runner() {
  const [{ editorUri, settings }, dispatch] = useTerminal();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const monaco = useMonaco();

  const typescriptWorker = useCallback(async () => {
    if (!monaco) {
      return;
    }

    const worker = await monaco.languages.typescript.getTypeScriptWorker();
    return worker();
  }, [monaco]);

  const runCode = useCallback(
    async (code: string) => {
      if (!iframeRef.current?.contentWindow) {
        return;
      }

      const iframeWindow = iframeRef.current.contentWindow;

      iframeWindow.location.reload();

      return new Promise((resolve) => {
        iframeRef.current!.onload = () => {
          const scope = new WeakMap();

          // stub the iframe console
          (iframeWindow as any).console = consoleStub((type, ...content) => {
            dispatch({
              type: 'SET_LOGS',
              logs: [
                {
                  type,
                  scope,
                  content,
                  id: generateId(),
                },
              ],
            });
          });

          // stub the iframe Promise and Proxy
          (iframeWindow as any).Promise = promiseStub(
            scope,
            (iframeWindow as any).Promise,
          );

          (iframeWindow as any).Proxy = proxyStub(
            scope,
            (iframeWindow as any).Proxy,
          );

          // listen for errors in the iframe
          iframeWindow.addEventListener('error', (e: ErrorEvent) => {
            e.preventDefault();
            dispatch({
              type: 'SET_LOGS',
              logs: [
                {
                  scope,
                  type: 'error',
                  id: generateId(),
                  content: [new Error(e.error)],
                },
              ],
            });
          });

          // create a script tag and append it to the iframe body
          const script = document.createElement('script');
          script.text = code;
          script.type = 'module';
          iframeWindow.document.body.appendChild(script);

          setTimeout(resolve, 250);
        };
      });
    },
    [iframeRef, dispatch],
  );

  useEvent(
    'RUN_CODE',
    async (data) => {
      if (!iframeRef.current) {
        return;
      }

      let code = data.code;

      if (settings.options.language === 'typescript') {
        const worker = await typescriptWorker();
        const { outputFiles } = await worker!.getEmitOutput(editorUri!);
        code = outputFiles?.[0]?.text!;
      }

      await runCode(code);
      dispatch({ type: 'RUN_COMPLETE' });
    },
    [settings.options.language, editorUri, typescriptWorker],
  );

  useEvent(
    'keydown',
    (_, event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: 'RUN_CODE' });
      }
    },
    [dispatch],
  );

  return (
    <iframe
      width={0}
      height={0}
      ref={iframeRef}
      src="about:blank"
      title="code runner"
      style={{ border: 0, zIndex: '-99999', position: 'absolute' }}
    />
  );
}
