import dracula from './dracula';
import githubDark from './github-dark';
import githubLight from './github-light';
import oneDarkPro from './one-dark-pro';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'github-dark': githubDark,
  'github-light': githubLight,
  'one-dark-pro': oneDarkPro,
  dracula,
};
