import { Collapsable } from './base';
import styles from './error.module.css';
import StringLog from './string';

interface Props {
  value: Error;
  isMinimized: boolean;
}

export default function ErrorLog({ value: err, isMinimized }: Props) {
  if (isMinimized) {
    return <StringLog value={err.message} className={styles.is_error} />;
  }

  return (
    <Collapsable className={styles.is_error}>
      <>{err.message}</>
      <>
        <br />
        {err.stack}
      </>
    </Collapsable>
  );
}
