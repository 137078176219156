import Convert from 'ansi-to-html';
import { getType } from '../../libs/utils';
import { LogWrapper } from './base';
import styles from './string.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: any;
};

const convert = new Convert();

export default function StringLog({ value: str, ...props }: Props) {
  const type = getType(str);

  return (
    <LogWrapper {...props} className={props.className ?? styles[type] ?? ''}>
      <div dangerouslySetInnerHTML={{ __html: convert.toHtml((str ?? type).toString()) }}></div>
    </LogWrapper>
  );
}
