import { Settings, useTerminal } from '../contexts/terminal';
import { useEvent } from '../libs/events';
import themes from '../themes';
import Dialog from './dialog';
import styles from './options.module.css';

export default function OptionsModal() {
  const [{ settings, showOptions }, dispatch] = useTerminal();

  useEvent(
    'keydown',
    (_, event: KeyboardEvent) => {
      if (event.metaKey && event.key === ',') {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: 'SHOW_OPTIONS' });
      }
    },
    [dispatch],
  );

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget as HTMLFormElement;
    const formData = new FormData(form);

    const tabSize = Number(formData.get('tabSize'));
    const language = formData.get(
      'language',
    ) as Settings['options']['language'];
    const theme = formData.get('theme') as Settings['options']['theme'];
    const layout = formData.get('layout') as Settings['layout'];

    dispatch({
      type: 'SET_SETTINGS',
      settings: {
        ...settings,
        layout,
        options: {
          ...settings.options,
          tabSize,
          language,
          theme,
        },
      },
    });

    dispatch({ type: 'HIDE_OPTIONS' });
  };

  const handleOnClose = () => {
    dispatch({ type: 'HIDE_OPTIONS' });
  };

  return (
    <Dialog
      title="Settings"
      open={showOptions}
      onClose={handleOnClose}
      showCloseButton={false}
    >
      <form onSubmit={handleOnSubmit} className={styles.form}>
        <fieldset>
          <label htmlFor="theme">Theme</label>
          <select id="theme" name="theme" defaultValue={settings.options.theme}>
            {Object.entries(themes).map(([key, theme]) => (
              <option value={key} key={key}>
                {(theme as any)['name']}
              </option>
            ))}
          </select>
        </fieldset>

        <fieldset>
          <label htmlFor="language">Language</label>
          <select
            id="language"
            name="language"
            defaultValue={settings.options.language}
          >
            <option value="typescript">TypeScript</option>
            <option value="javascript">JavaScript</option>
          </select>
        </fieldset>

        <fieldset>
          <label htmlFor="layout">Layout</label>
          <select id="layout" name="layout" defaultValue={settings.layout}>
            <option value="horizontal">Horizontal</option>
            <option value="vertical">Vertical</option>
          </select>
        </fieldset>

        <fieldset>
          <label htmlFor="tabSize">Tab Size</label>
          <input
            min="1"
            max="10"
            id="tabSize"
            type="number"
            name="tabSize"
            defaultValue={settings.options.tabSize}
            required
          />
        </fieldset>

        <fieldset style={{ textAlign: 'right' }}>
          <button
            type="button"
            onClick={() => dispatch({ type: 'HIDE_OPTIONS' })}
          >
            Cancel
          </button>
          <button type="submit">Save</button>
        </fieldset>
      </form>
    </Dialog>
  );
}
