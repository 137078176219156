import { Collapsable, PropertyList, LogValue } from './base';
import styles from './set.module.css';
import StringLog from './string';

interface Props {
  value: Set<any>;
  scope: WeakMap<any, any>;
  isMinimized: boolean;
}

export default function SetLog({ value: set, scope, isMinimized }: Props) {
  const setArray = Array.from(set);

  if (isMinimized) {
    return <StringLog value={`Set(${set.size})`} className={styles.is_set} />;
  }

  return (
    <Collapsable className={styles.is_set}>
      <>
        Set({`${set.size}`}) {'{'}
        {setArray.slice(0, 5).map((value, index) => {
          return (
            <>
              <LogValue scope={scope} value={value} isMinimized />
              {index < setArray.length - 1 && ','}
            </>
          );
        })}
        {setArray.length > 5 && ' …'}
        {'}'}
      </>
      <>
        <PropertyList
          scope={scope}
          list={setArray.map((value, index) => [index, value])}
          size={{ key: 'size', value: set.size }}
        />
      </>
    </Collapsable>
  );
}
