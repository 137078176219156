import { useEffect, useRef } from 'react';

import styles from './dialog.module.css';
import Icons from './icons';

interface Props {
  title: string;
  open?: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  children: React.ReactNode;
}

export default function Dialog({
  title,
  onClose,
  open = false,
  showCloseButton = true,
  children,
}: Props) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const dialog = dialogRef.current;

    if (!dialog) {
      return;
    }

    function handleOnClose() {
      onClose?.();
    }

    dialog?.addEventListener('close', handleOnClose);

    return () => {
      dialog?.removeEventListener('close', handleOnClose);
    };
  });

  useEffect(() => {
    if (open) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <dialog ref={dialogRef} className={styles.container}>
      <header className={styles.header}>
        {title}
        {showCloseButton && (
          <button onClick={onClose} className={styles.close}>
            <Icons.Close width={20} height={20} />
          </button>
        )}
      </header>
      <section className={styles.body}>{children}</section>
    </dialog>
  );
}
