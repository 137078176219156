import { useEffect } from 'react';

export function subscribe(
  type: string,
  listener: EventListenerOrEventListenerObject,
) {
  window.addEventListener(type, listener);
}

export function unsubscribe(
  type: string,
  listener: EventListenerOrEventListenerObject,
) {
  window.removeEventListener(type, listener);
}

export function publish(type: string, detail: any) {
  const event = new CustomEvent(type, { detail });
  window.dispatchEvent(event);
}

export function useEvent(
  eventName: string,
  callback: (data: any, event: any) => Promise<void> | void,
  deps: any[] = [],
) {
  useEffect(() => {
    async function listener(event: Event) {
      await callback((event as CustomEvent).detail, event);
    }

    subscribe(eventName, listener);

    return () => unsubscribe(eventName, listener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, callback, ...deps]);
}
