import { parseFunction } from '../../libs/utils';
import { LogWrapper } from './base';
import styles from './function.module.css';

interface Props {
  value: Function;
  isMinimized: boolean;
}

export default function FunctionLog({ value: fun, isMinimized }: Props) {
  const func = parseFunction(fun);

  return (
    <LogWrapper>
      {isMinimized ? (
        func.symbol
      ) : (
        <>
          <span className={styles.symbol}>{func.symbol}</span> {func.body}
        </>
      )}
    </LogWrapper>
  );
}
