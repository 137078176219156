import { Dispatch, createContext, useContext, useReducer } from 'react';

import { EditorOptions } from '../components/editor';
import { publish } from '../libs/events';
import { Type } from '../libs/stubs';

export type Settings = {
  options: EditorOptions;
  layout: 'horizontal' | 'vertical';
};

export type Log = {
  id: string;
  type: Type;
  content: any[];
  scope: WeakMap<any, any>;
};

interface State {
  logs: Log[];
  settings: Settings;
  code?: string;
  editorUri?: string;
  running: boolean;
  showOptions: boolean;
  showHelp: boolean;
  alertText?: string;
}

type Action =
  | {
      type:
        | 'CLEAR_LOGS'
        | 'SHOW_HELP'
        | 'HIDE_HELP'
        | 'RUN_CODE'
        | 'RUN_COMPLETE'
        | 'SHOW_OPTIONS'
        | 'HIDE_OPTIONS';
    }
  | {
      type: 'SET_LOGS';
      logs: Log[];
    }
  | {
      type: 'SET_CODE';
      code?: string;
    }
  | {
      type: 'ON_CODE_CHANGE';
      code?: string;
    }
  | {
      type: 'SET_SETTINGS';
      settings: Settings;
    }
  | {
      type: 'SET_EDITOR_URI';
      uri: string;
    }
  | {
      type: 'SET_ALERT';
      text?: string;
    };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_LOGS':
      state = {
        ...state,
        logs: [...state.logs, ...action.logs],
      };
      break;
    case 'CLEAR_LOGS':
      state = {
        ...state,
        logs: [],
      };
      break;
    case 'SET_CODE':
      state = {
        ...state,
        code: action.code,
      };
      break;
    case 'ON_CODE_CHANGE':
      state = {
        ...state,
        code: action.code,
      };
      break;
    case 'RUN_CODE':
      state = {
        ...state,
        running: true,
      };
      break;
    case 'RUN_COMPLETE':
      state = {
        ...state,
        running: false,
      };
      break;
    case 'SET_EDITOR_URI':
      state = {
        ...state,
        editorUri: action.uri,
      };
      break;
    case 'SET_SETTINGS':
      state = {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings,
        },
      };
      break;
    case 'SHOW_OPTIONS':
      state = {
        ...state,
        showOptions: true,
      };
      break;
    case 'HIDE_OPTIONS':
      state = {
        ...state,
        showOptions: false,
      };
      break;
    case 'SHOW_HELP':
      state = {
        ...state,
        showHelp: true,
      };
      break;
    case 'HIDE_HELP':
      state = {
        ...state,
        showHelp: false,
      };
      break;
    case 'SET_ALERT':
      state = {
        ...state,
        alertText: action.text,
      };
      break;
    default:
      throw new Error('Invalid action');
  }

  publish(action.type, state);

  return state;
}

export const TerminalContext = createContext<[State, Dispatch<Action>] | null>(
  null,
);

export function TerminalProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    logs: [],
    running: false,
    showOptions: false,
    showHelp: false,
    settings: {
      layout: 'horizontal',
      options: {
        tabSize: 2,
        theme: 'github-dark',
        language: 'typescript',
      },
    },
  });

  return (
    <TerminalContext.Provider value={[state, dispatch]}>
      {children}
    </TerminalContext.Provider>
  );
}

export function useTerminal() {
  const context = useContext(TerminalContext);

  if (!context) {
    throw new Error('useTerminal must be used within a TerminalContext');
  }

  return context;
}
