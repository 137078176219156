import { Collapsable, PropertyList, LogValue } from './base';
import styles from './proxy.module.css';
import StringLog from './string';

interface Props {
  value: Record<any, any>;
  scope: WeakMap<any, any>;
  isMinimized: boolean;
}

export default function ProxyLog({ value: prox, scope, isMinimized }: Props) {
  if (isMinimized) {
    return <StringLog value="Proxy" className={styles.is_proxy} />;
  }

  const meta = scope.get(prox);

  return (
    <Collapsable className={styles.is_proxy}>
      <>
        Proxy {'('}
        <LogValue scope={scope} value={meta.target} isMinimized />
        {')'}
      </>
      <>
        <PropertyList
          scope={scope}
          list={[
            ['[[ProxyHandler]]', meta.handler],
            ['[[ProxyTarget]]', meta.target],
          ]}
        />
      </>
    </Collapsable>
  );
}
